var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xl":"7"}},[_c('validation-provider',{attrs:{"name":"Title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Title","outlined":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"7"}},[_c('validation-provider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Full name","outlined":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","xl":"7"}},[_c('v-text-field',{attrs:{"type":"text","label":"Post nominal letter","outlined":""},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.postNominalLetters),callback:function ($$v) {_vm.$set(_vm.form, "postNominalLetters", $$v)},expression:"form.postNominalLetters"}})],1),_c('v-col',{attrs:{"cols":"12","xl":"7"}},[_c('validation-provider',{attrs:{"name":"Email","rules":_vm.requireEmail ? 'required|email' : 'email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","label":"Email","outlined":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1)],1),_c('v-row',{attrs:{"id":"editor-container"}},[_c('v-textarea',{staticClass:"d-none",attrs:{"type":"text","label":"profile"},model:{value:(_vm.form.profile),callback:function ($$v) {_vm.$set(_vm.form, "profile", $$v)},expression:"form.profile"}}),_c('v-col',{staticClass:"d-flex flex-column"},[_c('div',[_c('p',{staticClass:"text-body-1"},[_vm._v("Speaker profile")])]),_c('div',{ref:"editor"})])],1),_c('v-row',[(
        _vm.existing &&
        _vm.existing.profileImageUrl &&
        _vm.existing.profileImageUrl.length &&
        !_vm.form.profileImageChanged
      )?_c('v-col',{staticClass:"existing-image",attrs:{"cols":"auto"}},[_c('p',[_vm._v("Promotional image:")]),_c('v-sheet',{staticClass:"existing-image__sheet pt-1 px-1",attrs:{"elevation":"1"}},[_c('section',{staticClass:"existing-image__close text-right"},[_c('v-btn',{attrs:{"x-small":"","fab":""},on:{"click":_vm.deleteImage}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('section',{staticClass:"existing-image__container"},[_c('img',{attrs:{"src":(_vm.assetsBaseUrl + "/" + (_vm.existing.profileImageUrl)),"alt":"promotional image"}})])])],1):_c('v-col',{attrs:{"cols":"12","xl":"6"}},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":"image/png, image/jpeg","placeholder":"Profile image","label":"Profile image","outlined":"","prepend-icon":"","append-icon":"mdi-image"},on:{"change":_vm.setProfileImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }