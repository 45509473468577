
















































































































import Vue, { PropType } from "vue";
import Quill from "quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import {
  ImageUploadDto,
  SpeakerAdminResponse,
  SpeakerUpsertRequest,
} from "@/clients/clients";
import { extend, setInteractionMode, ValidationProvider } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { mapState } from "vuex";

extend("required", { ...required, message: "This field is required" });
setInteractionMode("lazy");
extend("email", email);

export default Vue.extend({
  name: "WebinarSpeakerForm",
  components: {
    ValidationProvider,
  },
  props: {
    existing: {
      type: Object as PropType<SpeakerAdminResponse | null>,
      default: null,
    },
    requireEmail: { type: Boolean, default: true },
    profileImageUrl: String,
  },
  data: (): {
    valid: boolean;
    quill: Quill | null;
    image: File | null;
    form: SpeakerUpsertRequest;
    rules: { (val: File): boolean }[];
    existingImageChanged: boolean;
  } => ({
    valid: true,
    quill: null,
    image: null,
    form: new SpeakerUpsertRequest(),
    rules: [
      (value: File): boolean => !value || value.size < 2000000,
      (value: File): boolean =>
        !value || /image\/jpeg|image\/jpg|image\/png/.test(value.type),
    ],
    existingImageChanged: false,
  }),
  computed: {
    ...mapState(["assetsBaseUrl"]),
  },
  created() {
    if (this.existing) {
      const speaker = new SpeakerUpsertRequest();
      speaker.init(this.existing);
      this.form = Object.assign({}, this.form, speaker);
    }
  },
  mounted() {
    this.quill = new Quill(this.$refs?.editor as Element, {
      modules: {
        toolbar: [
          [{ header: [false, 2, 3, 4, 5] }],
          ["bold", "italic", "underline", "link"],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
          ["clean"],
        ],
      },
      placeholder: "Enter webinar information...",
      theme: "snow",
    });
    this.quill.on("text-change", () => {
      const converter = new QuillDeltaToHtmlConverter(
        this.quill?.getContents()?.ops ?? [],
        {}
      );
      this.form.profile = converter.convert();
      this.$emit("input", this.form);
    });
    if (this.existing && this.existing.profile) {
      this.quill.clipboard.dangerouslyPasteHTML(this.existing?.profile);
    }
  },
  methods: {
    async setProfileImage(): Promise<void> {
      if (this.image !== null) {
        const arrayBuffer = await this.image?.arrayBuffer();
        const bytesArray = new Uint8Array(arrayBuffer).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        );
        this.form.profileImage = new ImageUploadDto();
        this.form.profileImage.base64Image = btoa(bytesArray);
        this.form.profileImage.fileName = this.image.name;
        this.$emit("input", this.form);
      }
    },
    deleteImage(): void {
      this.form.profileImageChanged = true;
      this.form = Object.assign({}, this.form);
      this.$emit("input", this.form);
    },
  },
});
