
























































































import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  ApiException,
  ImageUploadDto,
  SpeakerUpsertRequest,
  WebinarAvailability,
  SpeakerAdminResponse,
  WebinarsAdminClient,
  WebinarUpdateSpeakersRequest,
  SpeakersAdminClient,
} from "@/clients/clients";
import { ISelectBoxItems } from "@/data/ISelectBoxItems";
import { ValidationObserver } from "vee-validate";
import WebinarSpeakerForm from "@/components/forms/Webinars/WebinarCreate/WebinarSpeakerForm.vue";
import { loginRequest } from "@/configs/authConfig";
import { httpClientFactory } from "@/services/http/http";
import AppPageLoading from "@/components/layout/AppPageLoading.vue";
import AppPageBanner from "@/components/layout/AppPageBanner.vue";
import AppPage from "@/components/layout/AppPage.vue";

export default Vue.extend({
  name: "WebinarEditSpeakersView",
  components: {
    AppPage,
    AppPageBanner,
    AppPageLoading,
    WebinarSpeakerForm,
    ValidationObserver,
  },
  props: {
    id: String,
  },
  data: (): {
    forms: SpeakerUpsertRequest[];
    selectedSpeaker: SpeakerAdminResponse | null;
    existingSpeakers: ISelectBoxItems[];
    loading: boolean;
    error: boolean;
    message: string;
  } => ({
    loading: true,
    error: false,
    message: "",
    forms: [],
    existingSpeakers: [],
    selectedSpeaker: null,
  }),
  computed: {
    ...mapGetters("Webinar", ["webinar", "settings"]),
    ...mapState(["assetsBaseUrl"]),
    requireEmails(): boolean {
      return this.webinar?.availability === WebinarAvailability.Live;
    },
  },
  async created() {
    if (!this.id) {
      await this.$router.push("/webinars");
      return;
    }
    await this.getWebinar(this.id);
    const speakers = this.webinar?.speakers?.map(
      (sp: SpeakerAdminResponse) => new SpeakerUpsertRequest(sp)
    );
    this.existingSpeakers = await this.getSpeakers();
    this.forms = [...speakers];
    this.loading = false;
  },
  methods: {
    ...mapActions("Webinar", ["getWebinar", "getSettings", "reset"]),
    async onSubmit() {
      this.loading = true;
      this.error = false;
      try {
        const tokenRequest = await this.$auth?.authClient.acquireTokenSilent(
          loginRequest
        );
        const accessToken = tokenRequest?.accessToken;
        const client = httpClientFactory(WebinarsAdminClient, accessToken);
        const request = new WebinarUpdateSpeakersRequest();
        request.init({
          id: this.id,
          speakers: this.forms,
        });
        const response = await client.updateWebinarSpeakers(this.id, request);
        await this.$router.push(`/webinars/${response.id}`);
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.message = `An error occurred: ${(e as ApiException).message}`;
      }
    },
    async getSpeakers(): Promise<ISelectBoxItems[]> {
      const tokenRequest = await this.$auth?.authClient.acquireTokenSilent(
        loginRequest
      );
      try {
        const accessToken = tokenRequest?.accessToken;
        const client = httpClientFactory(SpeakersAdminClient, accessToken);
        const response = await client.getSpeakers();
        if (response?.speakers?.length) {
          return response.speakers.map((item: SpeakerAdminResponse) => {
            const name = [
              item.title ?? "",
              item.fullName ?? "",
              item.postNominalLetters ?? "",
            ]
              .filter((p) => p !== "")
              .join(" ");
            return { text: name, value: item };
          });
        }
        return [];
      } catch (e) {
        return [];
      }
    },
    getExistingSpeaker(
      form: SpeakerUpsertRequest
    ): SpeakerAdminResponse | null {
      if (!form.id) return null;
      const existing = this.existingSpeakers.find(
        (p) => p.value.id === form.id
      );
      return existing?.value ?? null;
    },
    addSpeaker(): void {
      this.forms.push(
        new SpeakerUpsertRequest({
          title: "",
          fullName: "",
          postNominalLetters: "",
          email: "",
          profile: "",
          profileImage: new ImageUploadDto(),
        })
      );
    },
    deleteSpeaker(index: number): void {
      this.$delete(this.forms, index);
    },
    addSelectedSpeaker(): void {
      if (this.forms.some((p) => p.id === this.selectedSpeaker?.id)) {
        return;
      }
      const existingSpeaker = new SpeakerUpsertRequest();
      existingSpeaker.init(this.selectedSpeaker);
      this.forms.push(existingSpeaker);
      this.selectedSpeaker = null;
    },
  },
});
